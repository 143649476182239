.button {
    position: relative;
    display: flex;
    background-color: rgba(92, 92, 92, 0.363);
    min-width: 200px;
    max-width: 600px;
    width: 50%;
    height: 20%;
    border-radius: 50px;
    border: 1px solid white;
}

.buttonOrganizer {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}
.buttonOrganizer p{
    font-family: 'Poppins', sans-serif;
    font-size: 3vh;
    text-align: center;
    width: 100%;
}
.buttonImg {
    height: 65%;
    padding-left: 10px;
}

a:-webkit-any-link {
    color: white;
    cursor: pointer;
    text-decoration: none;
}

@media (orientation: portrait) {
    .button{
        height: 15%;
    }
    .buttonOrganizer p{
        font-size: 2vh;
    }
}