body {
    display: block;
    margin: 0;
}
.mainContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background: url("./imageDanitattoo.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}
.buttonContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    height: 30%;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    align-self: center;
    top: 60%;
}
@media(max-width: 675px){
    .buttonContainer{
        top: 55%;
    }
    .mainContainer{
        background: url("./Imagewalpaper_mobile.png");
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 40%;
    }
}

